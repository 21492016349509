<template>
  <shipblu-prompt
    class="shipment-modal"
    @close="closeModal"
    @cancel="closeModal"
    :active.sync="moveTaskListModal"
    :title="$t('Create and move to new task list')"
    :buttons-hidden="true">
    <div class="vx-row mb-2 p-6">
      <v-select class="select-large mt-10 w-full" name="agent" v-model="taskList.assigned_to"
        v-validate="'required'" :placeholder="$t('Agent')" label="fullName" :options="agents" />
      <span class="text-danger text-sm" v-show="errors.has('agent')">{{ errors.first('agent') }}</span>
    </div>
    <vs-alert v-if="preventOrders.length > 0" color="warning" class="mb-base">{{$t('These selected orders can\'t be moved')}} {{ preventOrders.map(item => item.tracking_number) }}</vs-alert>
    <div class="grid grid-cols-2 gap-4 mt-4">
      <button @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
      <button @click="addTaskList" :disabled="preventOrders.length > 0" :class="preventOrders.length > 0 ? 'disable-btn btn' : 'active-btn btn'">{{ $t('Create and Move') }}</button>
    </div>
  </shipblu-prompt>
</template>
<script>
import vSelect from 'vue-select'
import ShipbluPrompt from '../../layouts/components/ShipBluPrompt.vue'
import { sendRequest }  from '../../http/axios/requestHelper.js'
import i18nData from '../../i18n/i18nData.js'
import common  from '@/assets/utils/common'

export default {
  props: ['moveTaskListModal', 'MATaskSheet', 'taskSheetOrder', 'agents', 'taskSheetType', 'taskListInfoData'],
  data () {
    return {
      taskList: {},
      preventOrders: []
    }
  },
  components: {
    vSelect,
    ShipbluPrompt
  },
  watch: {
    moveTaskListModal (val) {
      if (val === true) this.disableMoveToTasklist()
    }
  },
  methods: {
    closeModal () {
      this.taskList = {}
      this.preventOrders = []
      this.$emit('moveTaskListModal', false)
    },
    disableMoveToTasklist () {
      this.taskSheetOrder.forEach(item =>  {
        if ((item.tracking_number[0] === '2' || (item.tracking_number[0] === '3' && item.tracking_number[1] === '2')) && item.cash_amount > 0 && (this.taskListInfoData.status === 'in_progress' || (this.taskListInfoData.custody_from_khazna > 0 && this.taskListInfoData.custody_status === 'received_by_agent'))) {
          this.preventOrders.push(item)
        }
      })
    },
    addTaskList () {
      this.$validator.validateAll().then(result => {
        if (result) {
          const ordersData = []
          const ordersCashData = []
          this.taskSheetOrder.forEach(item => {
            if (item.tracking_number) {
              if (item.tracking_number[0] === '1' || (item.tracking_number[0] === '3' && item.tracking_number[1] === '1') || item.tracking_number[0] === '2'
              || (item.tracking_number[0] === '3' && item.tracking_number[1] === '2')) {
                ordersData.push(item.id)
              } else if (item.tracking_number[0] === '4') {
                ordersCashData.push(item.id)
              }
            }
          })
          if (this.taskSheetOrder.length > 0) {
            if (!this.isOnline) this.$emit('moveTaskListModal', false)
            this.$vs.loading()
            const sendDate = {
              orders: ordersData,
              cash_collections: ordersCashData,
              old_task_sheet: this.$route.params.taskListID,
              task_sheet_type: this.taskSheetType,
              agent: this.taskList.assigned_to.id
            }
            if (ordersData.length <= 0 || ordersCashData.length <= 0) {
              sendRequest(false, false, this, 'api/v1/task-sheets/transformation/', 'post', sendDate, true,
                () => {
                  this.taskList = {}
                  this.$emit('moveTaskListModal', false)
                  this.$emit('loadShipments')
                  this.$vs.notify({
                    color:'success',
                    title:i18nData[this.$i18n.locale]['Success'],
                    text:i18nData[this.$i18n.locale]['Task List'] + i18nData[this.$i18n.locale][' has been created successfully!'],
                    position: 'top-center'
                  })
                }, (error) => {
                  common.notifyResponseError(this, error)
                  this.$vs.loading.close()
                })
            }
          }
        }
      })
    }
  }
}
</script>

<style lang='scss'>
</style>